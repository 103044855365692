import React from "react"
import indexStyles from "../styles/index.module.scss"
import Layout from "../components/layout"
import { Button, Icon, Row, Col, Divider } from "antd"
import logo from "../images/logo.jpg"
import useShowOnScolled from "../useShowOnScrolled"

const IndexPage = () => {
  const shown = useShowOnScolled(200)

  return (
    <Layout>
      <Row align="middle" justify="center">
        <Col span={24}>
          <div className={indexStyles.heroImage}>
            <div
              className={
                shown
                  ? `${indexStyles.heroText} ${indexStyles.heroTextAnimation}`
                  : `${indexStyles.heroText}`
              }
            >
              <h1>Caring... Healing... Leading...</h1>
              <a href="tel:+91-6351901836">
                <Button
                  type="primary"
                  size="large"
                  className={indexStyles.buttonText}
                >
                  Get Care
                  <Icon
                    twoToneColor="#000000"
                    className={indexStyles.icon}
                    type="phone"
                  />
                </Button>
              </a>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          offset={6}
          xs={{ span: 20, offset: 1 }}
          md={{ span: 16, offset: 4 }}
          lg={{ span: 16, offset: 4 }}
        >
          <img
            className={
              shown
                ? `${indexStyles.logo} ${indexStyles.logoOnScroll}`
                : `${indexStyles.logo}`
            }
            src={logo}
          />
          <p
            className={
              shown
                ? `${indexStyles.description} ${indexStyles.descriptionOnScroll}`
                : `${indexStyles.description}`
            }
          >
            We are a team of dedicated healthcare professionals committed to
            provide holistic care to our patients, educating them, and building
            long-term relationships. Our team comprises of experienced doctors
            who have worked in the healthcare for over 30 years.
            <br />
            <br />
            Located in Gandhinagar, the capital city of Gujarat, today, our
            hospital boasts of 30 beds, X-Ray Room, Special Rooms, Ambulance,
            and operation theater.
          </p>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[0, 48]}>
        <Col
          span={7}
          offset={4}
          justify="center"
          xs={{ span: 20, offset: 1 }}
          md={{ span: 7, offset: 4 }}
          lg={{ span: 7, offset: 4 }}
        >
          <h4>
            <Icon type="video-camera" /> Watch video on Alcohol Addiction
          </h4>
          <div className={indexStyles.video}>
            <iframe
              src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fdrrajendra.anand%2Fvideos%2F173964483993148%2F&show_text=0&width=560"
              scrolling="no"
              frameborder="0"
              allowTransparency="true"
              allowFullScreen="true"
            ></iframe>
          </div>
        </Col>
        <Col
          span={7}
          offset={2}
          justify="center"
          xs={{ span: 20, offset: 1 }}
          md={{ span: 7, offset: 2 }}
          lg={{ span: 7, offset: 2 }}
        >
          <h4>
            <Icon type="video-camera" /> Watch video on Child Alcohol Addiction
          </h4>
          <div className={indexStyles.video}>
            <iframe
              src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fdrrajendra.anand%2Fvideos%2F170619414327655%2F&show_text=0&width=560"
              scrolling="no"
              frameborder="0"
              allowTransparency="true"
              allowFullScreen="true"
            ></iframe>
          </div>
        </Col>
      </Row>
      <Row gutter={[0, 48]}>
        <Col
          span={7}
          offset={4}
          justify="center"
          xs={{ span: 20, offset: 1 }}
          md={{ span: 7, offset: 4 }}
          lg={{ span: 7, offset: 4 }}
        >
          <h4>
            <Icon type="video-camera" /> Watch video on Suicide Prevention
          </h4>
          <div className={indexStyles.video}>
            <iframe
              src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fdrrajendra.anand%2Fvideos%2F152812789441651%2F&show_text=0&width=560"
              scrolling="no"
              frameborder="0"
              allowTransparency="true"
              allowFullScreen="true"
            ></iframe>
          </div>
        </Col>
        <Col
          span={7}
          offset={2}
          justify="center"
          xs={{ span: 20, offset: 1 }}
          md={{ span: 7, offset: 2 }}
          lg={{ span: 7, offset: 2 }}
        >
          <h4>
            <Icon type="video-camera" /> Watch video on Addiction
          </h4>
          <div className={indexStyles.video}>
            <iframe
              src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fdrrajendra.anand%2Fvideos%2F126377535418510%2F&show_text=0&width=560"
              scrolling="no"
              frameborder="0"
              allowTransparency="true"
              allowFullScreen="true"
            ></iframe>
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export default IndexPage
